import { DateTime } from "luxon";
import Consts from "./consts";
class Formatter {
  formatDate(input, sFormat) {
    //var sDate = DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_SHORT);
    let dateTimeValue;
    if (input instanceof Date) {
      dateTimeValue = DateTime.fromJSDate(input);
    } else if (typeof input == "string") {
      dateTimeValue = DateTime.fromISO(input);
    } else if (typeof input == "number") {
      dateTimeValue = DateTime.fromMillis(input);
    }
    if (dateTimeValue) {
      try {
        return dateTimeValue.toFormat(sFormat || Consts.FORMAT_DATE_US);
      } catch {
        return dateTimeValue.toLocaleString(DateTime.DATE_SHORT);
      }
    } else {
      return input;
    }
  }
  formatTime(input, sFormat) {
    let dateTimeValue;
    if (input instanceof Date) {
      dateTimeValue = DateTime.fromJSDate(input);
    } else if (typeof input == "number") {
      dateTimeValue = DateTime.fromMillis(input);
    } else if (typeof input == "string") {
      dateTimeValue = DateTime.fromISO(input);
    }
    if (dateTimeValue) {
      try {
        return dateTimeValue.toFormat(sFormat || Consts.FORMAT_DATE_TIME_VN);
      } catch {
        return dateTimeValue.toLocaleString(DateTime.TIME_WITH_SECONDS);
      }
    } else {
      return input;
    }
  }
  formatNumber(input) {
    if (input == null || input == undefined) {
      return "";
    }
    return input.toLocaleString();
  }
  formatPackageProcessStatus(t, status) {
    switch (status) {
      case 1:
        return t('package.inited');
      case 2:
        return t('package.shipped');
      case 3:
        return t('package.stored');
      case 4:
        return t('package.delivering');
      case 5:
        return t('package.delivered');
    }
  }
}

export default new Formatter();
