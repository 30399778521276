const Consts = {
  FORMAT_DATE_US: "MM/dd/yyyy",
  FORMAT_DATE_TIME_US: "MM/dd/yyyy hh:mm:ss",
  FORMAT_DATE_VN: "dd/MM/yyyy",
  FORMAT_TIME: "hh:mm:ss",
  FORMAT_DATE_TIME_VN: "dd/MM/yyyy hh:mm:ss",
  ORGTYPE_OWNER: "owner",
  REMOTE_FEE_RATE: 20, //percentile
  POUND2KG: 0.453592,
  MAX_RECEIPT_ITEM_ROWS: 22,
  ROW_PER_PAGE_OPTIONS: [10, 50, 100, 200],
  DEFAULT_PAGE_ROWS: 10,
  MAX_WEIGHT_DIFF: 1, //Sai so 1 pound
  MAX_WEIGHT_DIFF_IN_PERCENTILE: 1, //Sai so in %
  DEFAULT_PACKAGE_NUMBER: 10,
  PACKGE_CODE_LENGTH: 3,
};
const GoodsTypes = [
  { "value": "normal", "label": "Bình thường" },
  { "value": "special", "label": "Đặc biệt" }
];
const DocumentTypes = [
  { "value": "MAWB", "label": "MAWB" },
  { "value": "HAWB", "label": "HAWB" },
  { "value": "MANIFEST", "label": "MANIFEST" },
  { "value": "ORIGINAL_RECEIPT", "label": "BIÊN NHẬN GỐC(ĐỐI TÁC GỬI)(PDF hoặc excel)" },
  { "value": "GOODS_LIST", "label": "LIST HÀNG FNT NHẬP HỆ THỐNG" },
  { "value": "CVCK", "label": "CVCK" },
  { "value": "CUSTOMS_DECLARATION", "label": "TỜ KHAI" },
  { "value": "ESTIMATED_SHIPMENT_COST", "label": "BẢNG CHI PHÍ LÔ DỰ KIẾN" },
  { "value": "FINAL_SHIPMENT_COST", "label": "BẢNG CHI PHÍ LÔ HOÀN TẤT" },
  { "value": "OTHER", "label": "CV KHÁC" },
];
const ShipmentStatuses = [
  { "value": "incomming", "label": "Đang về" },
  { "value": "arrived", "label": "Đã đáp" },
  { "value": "customs_cleared", "label": "Đã thông quan" },
  { "value": "imported", "label": "Đã nhập kho" }
];
const PackageProcessStatuses = [
  { "value": "incomming", "label": "Đang về" },
  { "value": "imported", "label": "Đã nhập kho" },
  { "value": "checked", "label": "Đã cân kiểm" },
  { "value": "exported", "label": "Đã xuất kho" },
  { "value": "delivered", "label": "Đã giao thành công" },
  { "value": "failed", "label": "Giao không thành công" }
];
const PackageAppearances = [
  { "value": "normal", "label": "Bình thường" },
  { "value": "damaged", "label": "Móp" },
  { "value": "broken", "label": "Vỡ" },
  { "value": "wet", "label": "Ướt" }
];
const PackageComplaints = [
  { "value": "none", "label": "Không" },
  { "value": "created", "label": "Đã khởi tạo" },
  { "value": "pending", "label": "Đang chờ" },
  { "value": "resolved", "label": "Đã giải quyết" }
];
export {
  Consts,
  GoodsTypes,
  DocumentTypes,
  ShipmentStatuses,
  PackageProcessStatuses,
  PackageAppearances,
  PackageComplaints
};
